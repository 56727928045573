<template>
  <div class="politique-de-confidentialite content">
    <section id="page-title" class="page-title-mini">
			<div class="container clearfix">
				<h1>{{ $t('legal.politique-de-confidentialite.title') }}</h1>
			</div>
		</section>
    <div class="content-wrap">
      <div class="container clearfix">
        <Fragment path="politique-de-confidentialite"></Fragment>
      </div>
    </div>
  </div>
</template>

<style>

</style>

<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import Fragment from '@fwk-client/components/panels/Fragment.vue';

@Component({
  components: {
	  Fragment
  },
  computed: {
	  
  }
})
export default class PolitiqueDeConfidentialite extends mixins(GenericPage) {

	

}
</script>